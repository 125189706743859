import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../../components/template"
import "../../styles/blog/blogpost.css"
import { changeTheNarrativeContent } from "../../content/blog/change-the-narrative"
import { SettingsContext } from "../../contexts/settings"

const ChangeTheNarrativePage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = changeTheNarrativeContent[settings.language]
    return (
        <Template currentPage="blog">
            <section>
            <article>
                <h1>
                    <Link to="/blog#change-the-narrative" class="back-arrow">{content.arrow}</Link>
                    {content.headline}
                </h1>
                <p>{content.date}</p>
                <div className="image-banner">
                    <img className="image-third" src="/images/blog/Graffiti-NIGHT.jpg" alt={content.altFirst}/>
                    <img className="image-third" src="/images/blog/Skyline.jpg" alt={content.altSecond}/>
                    <img className="image-third" src="/images/blog/Walking-Frankfurt.jpg" alt={content.altThird}/>
                </div>
                <p>
                    {content.first}
                </p>
                <p>
                    {content.second}
                </p>
                <p>
                    <a className="link" href="/documents/blog/documenting-nocturnal-flaneuserie-filming-instructions.pdf" target="_blank" rel="noopener noreferrer">
                        Download instructions for documenting nocturnal flaneuserie (English) (PDF)
                    </a>
                </p>
                <p>
                    <a className="link" href="/documents/blog/nOu_Reading-Suggestions_Documenting-Nocturnal-Flaneuserie.pdf" target="_blank" rel="noopener noreferrer">
                        Download suggestions for reading and listening about the flaneuse at night (English) (PDF)
                    </a>
                </p>
                <h3>{content.secondHeadlineFirst}</h3>
                <p>
                    {content.third}
                </p>
                <p className="text-middle-blog">
                {content.fourthFirst}
                <a className="link-middle" href={content.bookLink} target="_blank" rel="noopener noreferrer">{content.fourthLink}</a>
                {content.fourthSecond}
                </p>
                <p>
                    {content.fifth}
                </p>
                <p>
                    {content.sixth}
                </p>
                <div className="text-soundcloud">
                    <p>
                        <b>{content.big}</b>
                        {content.podcastText}
                        <a className="link" href="https://www.theguardian.com/society/gallery/2009/mar/09/rut-blees-luxemburg-photography" target="_blank" rel="noopener noreferrer">
                            {content.podcastPhotoLink}
                        </a>
                    </p>
                    <iframe title="Soundcloud Shooting the urban night with Rut Blees Luxemburg" className="soundcloud" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/364353665&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                </div>
                <p>
                    {content.seventh}
                </p>
                <p>
                    {content.eighth}
                </p>
                <h3>{content.secondHeadlineSecond}</h3>
                <p>
                    {content.ninth}
                </p>
                <p>
                    {content.tenthFirst}
                    <a className="link" href="https://opinionator.blogs.nytimes.com/2013/09/01/walking-while-black-in-the-white-gaze/" target="_blank" rel="noopener noreferrer">
                        {content.tenthLinkFirst}
                    </a>
                    {content.tenthSecond}
                    <a className="link" href="https://feministkilljoys.com/2014/08/04/making-strangers/" target="_blank" rel="noopener noreferrer">
                        {content.tenthLinkSecond}
                    </a>
                    {content.tenthThird}
                </p>
                <Link className="link" to="/blog/who-are-we-afraid-of">{content.eleventh}</Link>
                <h2 className="quote">{content.secondHeadlineThird}</h2>
                <p className="centered small">{content.twelfth}</p>
                <p>
                    {content.thirteenth}
                </p>
                <h3>{content.secondHeadlineFourth}</h3>
                <p>
                    {content.fourteenth}
                </p>
                <p>
                    {content.fifteenth}
                </p>
                <p>
                    {content.sixteenth}
                </p>
                <h3>{content.secondHeadlineFifth}</h3>
                <p>
                    {content.seventeenth}
                </p>
                <h4>{content.thirdHeadlineFirst}</h4>
                <p>
                    {content.eighteenthFirst}
                    <a className="link" href="https://graumalerei.com/" target="_blank" rel="noopener noreferrer">
                        {content.eighteenthLinkFirst}
                    </a>
                    {content.eighteenthSecond}
                    <a className="link" href="https://cargocollective.com/annabennerstudio" target="_blank" rel="noopener noreferrer">
                        {content.eighteenthLinkSecond}
                    </a>
                    {content.eighteenthThird}
                </p>
                <p>
                    {content.nineteenthFirst}
                    <a className="link" href="http://wildpeararts.com/" target="_blank" rel="noopener noreferrer">
                        {content.nineteenthLinkFirst}
                    </a>
                    {content.nineteenthSecond}
                    <a className="link" href="https://eefb.org/" target="_blank" rel="noopener noreferrer">
                        {content.nineteenthLinkSecond}
                    </a>
                    {content.nineteenthThird}<br/>
                    <a className="link" href="https://vimeo.com/zoeaiano" target="_blank" rel="noopener noreferrer">
                        {content.nineteenthLinkThird}
                    </a>
                </p>
                <p>
                    {content.thirdHeadlineSecond}
                </p>
                <p>
                    {content.twentiethFirst}
                    <a className="link" href="https://www.nytimes.com/2020/10/06/opinion/all-her-dying-lovers-nazis.html" target="_blank" rel="noopener noreferrer">
                        {content.twentiethLink}
                    </a>
                    {content.twentiethSecond}
                </p>
                <div className="end-of-site">
                    <a href="https://www.nytimes.com/2020/10/06/opinion/all-her-dying-lovers-nazis.html" target="_blank" rel="noopener noreferrer">
                        <img className="video" src="/images/blog/all-her-dying-lovers.jpg" alt={content.altFourth}/>
                    </a>
                </div>
            </article>    
            </section>
        </Template >
    )
}

export default ChangeTheNarrativePage