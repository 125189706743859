const changeTheNarrativeContent = {
    german: {
        date: "14. Februar 2021",
        arrow: "BLOG << ",
        headline: " Den Narrativ ändern – Ein kleiner Bericht vom Workshop „Documenting Nocturnal Flâneuserie“ von Anna Benner und Zoë Aiano",
        first: "In unseren zweitägigen Workshops „Documenting Nocturnal Flâneusrie“ von Anna Benner und Zoë Aiano am 16.-17. Januar und 18.-19. Januar haben wir genau das versucht, was der Titel ankündigt: jede der Teilnehmer:innen zog für sich in die Nacht, um ihr nächtliches Flanieren mit Film- und Sprachaufnehmen sowie wie Fotos zu dokumentieren. Getrieben von der Frage, was das nächtliche Flanieren für jede:n individuell aber auch kollektiv als weiblich identifizierte und nicht-binäre Menschen bedeutet. Kann es so etwas wie die Flaneuse geben? In diesem Blogeintrag reflektieren wir die Diskussionen und geben euch zusätzliches Material an die Hand, um ihre Stadt mit visuellen Mitteln zu entdecken.",
        second: "Aufgrund der Corona-Pandemie haben wir uns für den Workshop an beiden Tagen digital getroffen. Die Nacht dazwischen galt uns als Experimentier-Raum. Nach einem ersten Kennenlernen und Austauschen von Ideen und Erfahrungen entließen die Künstlerinnen Anna Benner und Zoë Aiano die Teilnehmer:innen mit einigen Aufgaben los, um zu flexen, zu flanieren, herumzulaufen an so unterschiedlichen Orten wie Frankfurt, Wien, Berlin… Am folgenden Tag trafen wir uns digital wieder, sprachen über unsere Erfahrungen.",
        secondHeadlineFirst: "„Ich muss etwas Aufregendes einfangen!“",
        third: "Sich durch die nächtliche Stadt bewegen, sich in ihr treiben lassen, ist eng mit der romantisierten Figur des Flaneurs verbunden. Einem Mann, der alleine durch die Straßen geht, sich vielleicht einsam fühlt, der das bunte Treiben, die nächtlichen (Sex-)Arbeiter:innen, das Leben beobachtet, aber nicht zurück angesehen wird – einfach weil er es kann, weil er nicht auffällt. Er ist sich sicher, die Stadt gehört ihm, seine ganze Körperhaltung drückt dieses Gefühl aus: Die Stadt wird ebenso wie ihre Menschen zu einem Objekt, das er sich aneignen kann. Das Bild, das wir von dem Flaneur kennen, hat er selbst geschrieben; er der unabhängige Flaneur, der Boheme, der Schriftsteller – er entscheidet, was Wert hat von ihm erzählt zu werden und hat damit auch das Bild der Städte wie Paris oder Berlin sowie ihren (nächtlichen) Bewohner:innen entscheidend geprägt.",
        fourthFirst: "Mehr über das Weißsein des Flaneurs und was es bedeutet, als Schwarzer aus der Arbeiterklasse in europäischen Metropolen unterwegs zu sein, lesen Sie in Johnny Pitts wunderbarem zeitgenössischen Reisetagebuch, das mit viel Wissen über (Schwarzes) Europa, Diaspora, Literatur, Rassismus, Politik des Kalten Krieges, Schwarzen Feminismus und vieles mehr verwoben ist: „Afropäisch“, deutsche Ausgabe erschienen in Suhrkamp (",
        bookLink: "https://www.suhrkamp.de/buecher/afropaeisch-johny_pitts_42941.html",
        fourthLink: "wenn du hier klickst, kannst du mehr zu dem Buch erfahren;",
        fourthSecond: " englische Ausgabe „Afropean“).",
        fifth: "Aber was bedeutet das für uns, die wir in diesem Workshop auf der Suche sind? Was bedeutet es für uns als Frauen und non-binäre Menschen, als nicht cis-männlich gelesene Körper durch die Nacht zu laufen? Einige Teilnehmer:innen erzählen, dass es im Rahmen des Workshops das erste Mal seit der Corona-Pandemie sein wird, dass sie sich Nachts durch die Stadt bewegen – mit der Versprechen des Flaneurs, etwas anderes oder Aufregendes zu erleben, im Gepäck.",
        sixth: "Zurück am nächsten Abend berichtet eine Teilnehmerin ihrer Enttäuschung und Wut, dass sich dieses Versprechen für sie nicht erfüllt hat. Es seien widersprüchliche Gefühle gewesen: zwischen dem Wunsch, das zu finden, was dem romantisiertem Bild des „besonderen“ Ortes entspricht, und gleichzeitig sehe in ihren Augen „alles scheiße“ aus. Sie sagt: „Ich hatte zwei Gefühle gegenüber allem, was ich gefilmt habe: es passt nicht zu der Idee und ich mag das, aber vielleicht habe ich auch ein Verlangen nach dieser Art von Idee, die die Realität repräsentiert – das Leben um mich herum und ich selbst, wie ich durch dieses Leben gehe, und das ist wirklich nicht da.“",
        big: "Hörtipp: ",
        podcastText: "Fotografin Rut Blees Luxemburg spricht darüber, wie sie versucht die vergessenen und unscheinbaren Orte einzufangen ",
        podcastPhotoLink: "(folge diesem Link, um eine Auswahl von Rut Blees Luxemburgs Nacht-Fotos zu sehen)",
        seventh: "Wir sprechen darüber, dass für uns das nächtliche Gehen häufig oft auf das Erreichen eines bestimmten Ziels und noch mehr auf unser ortsgebundenes soziales Leben beschränkt ist – durch die geschlossenen Bars, Kneipen und Clubs bleibt die Nacht nun häufig menschenleer. Aber trotzdem, sagt eine Teilnehmerin, sei für sie das nächtliche alleine Herumschweifen, ein Ort der Sehnsucht. In beleuchtete Fenster schauen und sich nach Gemütlichkeit sehnen, die sie sich dort vorstelle, oder – vor Corona – die Leute, die aus dem Club kommen, sehen und sich nach dem zügellosen Lebensstil sehen, den sie in den Leuten zu erkennen glaubt. „Das macht mich auf eine bestimmte Art traurig.“ Ja, vielleicht finden wir manchmal sogar melancholische Freude an dieser Traurigkeit?",
        eighth: "Andere erinnern sich, dass sie die Einsamkeit der corona-bedingten leeren Straßen, die an jenen Abenden verschneit waren, auch genossen haben. Diese Orte, die über Tag meist belebt sind, sich angefühlt haben, als wären sie nur für sie da. Es bedeutete auch weniger Catcalling. Weniger Hemmungen, vielleicht. Eine andere Erfahrung im Gegensatz zu anderen Nächten, die gefüllt waren mit Catcalls, ungebetenen Annäherungen, Arschgrabschen, festgehaltenen Schlüsseln und Pfeffersprays, Gewalt. Stattdessen: Eine einsame Tänzerin im weißen Kleid, die mit Kopfhörern am Fluss tanzt – ein Vorstellung nur für die Flaneuse, die einfach mittanzt.",
        secondHeadlineSecond: "„Warum habe ich weniger Angst, wenn ich nicht alleine gehe?“",
        ninth: "In den Instruktionen gaben Anna und Zoë, die Aufgabe alleine in den Park zu gehen. Die meisten Teilnehmerinnen sagten, sie hätten es nicht alleine gemacht. Nur wenn sie jemand dabei hatten, fühlten sie sich sicher genug, in den Park zu gehen. Warum ist das so? Warum fühlen wir uns so viel sicherer, wenn wir nicht alleine sind? Können wir als Flaneuse nur alleine unterwegs sein? Was ist mit Müttern mit Kind? Was ist, wenn ich einen Hund dabei habe? Was, wenn ich telefoniere? Hält uns das eigentlich davon ab, die Stadt „richtig“ zu erleben? Oder ist diese Art von feierlicher Einsamkeit nur das Phantasma des Flaneurs, das ihn von dem isoliert, was es bedeutet, tatsächlich an dem Ort zu leben? Die einsame Figur des Flaneurs ist unmöglich in Beziehungen zu denken – er bewegt sich „frei“ durch die Stadt und ohne Verantwortung für irgendwas oder irgendwen. Vielleicht ist die Flaneuse dagegen jemand, die eingebunden ist in Beziehung, die Verantwortung trägt und Fürsorge gibt – was vielleicht auch für sie Sicherheit birgt, weil sie weiß, dass sie nicht alleine ist. Die Flaneuse erlebt eine Stadt, die keine abstrakte Idee ist, sondern eine gelebte Realität, die mit all ihren Geschichten, Biografien, Einschränkungen, Beziehungen und Freuden daher kommt.",
        tenthFirst: "Trotzdem ist Angst eine ständige und ambivalente Wegbegleiterin vieler Teilnehmerinnen. Eine berichtet, wie sie in Panik geriet, nachdem sich ihr ein einsamer Mann näherte. Erst Sekunden später erkennt sie, dass es ein Pfandflaschensammler ist und fragt entsetzt: „Welche Art von Ängsten projiziere ich?“ Viele von uns, die weiße Frauen sind, kriegen bereits früh erzählt, dass es Nachts nicht sicher ist und wir vor „fremden“ Männern fürchten müssen (in englischen Sprachraum häufig vermittelt durch den Slogan „Stranger Danger“, übersetzt so viel wie „Der Fremde ist gefährlich“). Dieses Narrativ des Fremden ist – so zeigen unter anderem George Yency (",
        tenthLinkFirst: "hier geht es zum englischen Artikel über Gehen und den weißen Blick",
        tenthSecond: ") und Sara Ahmed (",
        tenthLinkSecond: "mit diesem Link geht es zum englischen Blogeintrag über Making Stragners",
        tenthThird: ") auf – stark mit rassistischen und klassistischen Narrativen verwoben.",
        eleventh: "Folge dem Link zum Blog-Beitrag „Wovor haben wir Angst? Der „Fremde“ als rassistische und klassistische Projektion.",
        secondHeadlineThird: "„Ich hoffe wirklich, dass die Flaneuse oder die radikale Flaneuserie ein Weg sein könnte, nicht nur uns selbst zu ermächtigen, hinauszugehen, sondern auch das Narrativ zu verändern, wo wir gefährdet sind oder warum wir gefährdet sind.“",
        twelfth: "Workshop-Teilnehmerin",
        thirteenth: "Es könne dabei helfen, den Fokus zu verlegen und ihn intersektional zu schärfen, welche Körper in der Dunkelheit gefährdet sind und warum. Wenn wir darüber nachdenken, wer oder was eine Flaneuse ist oder was sie kann, dann können wir das nicht, ohne sämtliche gesellschaftliche Machtverhältnisse mitzudenken.",
        secondHeadlineFourth: "„Kein Ziel, aber einen Grund haben“ – nächtliche Flâneuserie filmisch festhalten",
        fourteenth: "Die Kamera in unserer Hand verändert die Wahrnehmung unseres Flanieren. Ohne dass die Kamera aufnimmt, kann sie unseren Blick lenken und ihn auf die Suche nach dem Aufregenden und Außergewöhnlichen schicken. Wir sprechen darüber, wie es uns mit dem Filmen ging. Die Kamera gebe ihr einen Grund ziellos zu sein, was sie normalerweise nie sei – vor allem nicht nachts, sagt eine Teilnehmerin. Eine andere Teilnehmerin berichtet, dass sie mit und durch die Kamera weniger Angst hatte, alleine unterwegs zu sein: „Ich hatte eine Distanz zwischen mir und der Szene und gleichzeitig war ich in ihr. Ich empfand die Kamera als ein ermächtigendes Objekt. Ich habe aktiv Besitz von der Nacht ergriffen.“",
        fifteenth: "Wir reden auch über den Spaß, die das Filmen machen kann, über die absurden Situationen, die entstanden sind. Eine filmende Person auf der anderen Seite ganz vertraut grüßen, um peinlich berührt festzustellen, dass sie gar keine Teilnehmer:in des Workshop ist. Absurde Schaufenster in der eignen Nachbarschaft entdecken, an schon hundert Mal unbeachtet vorbei gelaufen sind.",
        sixteenth: "Ein nächtlicher Spaziergang kann „belebend“ wirken, uns helfen „unseren Körper zu spüren“ oder wir können uns einfach frei fühlen.",
        secondHeadlineFifth: "Documenting Nocturnal Flaneuserie auf dem Festival",
        seventeenth: "Aus den aufgenommen Filmaufnahmen, Fotos und Memos sowie aus unseren Online-Gesprächen machen die Filmemacherinnen Anna Benner und Zoë Aiano einen dokumentarischen animierten Kurzfilm, der zum ersten Mal auf dem Festival Nocturnal Unrest gezeigt wird, das im Mai 2021 im Künstler:innenhaus Mousonturm und online stattfinden wird.",
        thirdHeadlineFirst: "Künstlerinnen:",
        eighteenthFirst: "Anna Benner ist eine Künstlerin und Filmemacherin, mit den Arbeitsschwerpunkten in Animation, Zeichnung und Installationen. Sie studierte Illustration und Filmästhetik in London und Oxford. Seit 2014 lebt und arbeitet sie in Berlin, wo sie ihre ",
        eighteenthLinkFirst:"Galerie graumalerei ",
        eighteenthSecond: " eröffnete, die sich auf zeitgenössische Illustration konzentriert und als empowernder Raum für Künstler:innen dienen soll, ihre eigene Arbeit voranzutreiben und in einem sicheren Raum zu erkunden. Ihr Hauptausdrucksmittel ist die digitale handgezeichnete Rotoskop-Animation. In ihrer Arbeit erforscht sie gerne die menschliche Psychologie und Emotionen, manchmal in ihrem gebrochenen Zustand, aber auch immer mit einer Portion Humor. Ihre Kunstwerke wurden in Berlin, London und Paris ausgestellt und ihre animierten Kurzfilme liefen auf Festivals weltweit. ",
        eighteenthLinkSecond: "Folge diesem Link, um mehr über Annas Arbeit zu lernen",
        eighteenthThird: " oder folge ihr auf Instagram: @annabennerstudio.",
        nineteenthFirst: "Eluned Zoë Aiano ist Filmemacherin, Video-Editorin und Übersetzerin mit Fokus auf Mittel- und Osteuropa. Ihr Interessengebiet ist die visuelle Ethnographie an der Grenze zwischen Dokumentation und Kunst, sozialer Praxis und Experiment. Derzeit arbeitet sie zusammen mit den anderen Mitgliedern von Wild Pear Arts an Flotacija, ihrem ersten Dokumentarspielfilm. Wild Pear/Divlja Kruška ist ein Team von vier Frauen mit dem gemeinsamen Ziel, mit einem Fokus auf die Balkanregion, unterrepräsentierte Perspektiven künstlerisch zu visualisieren (",
        nineteenthLinkFirst: "klicke hier, um mehr über Wild Pear zu erfahren",
        nineteenthSecond: "). Sie ist auch eine regelmäßige Mitarbeiterin des East European Film Bulletin (",
        nineteenthLinkSecond: "klicke hier um mehr über das Bulletin zu lesen",
        nineteenthThird: ").",
        nineteenthLinkThird: "Klicke hier, um mehr über Zoës Arbeit zu erfahren.",
        thirdHeadlineSecond: "Wollt ihr einen Eindruck bekommen:",
        twentiethFirst: "Das gemeinsames Projekt „All her dying lovers“ der beiden, über eine urbane Legende einer Nazi-mordenden Krankenschwester in Tschescheslovakai zu Zeiten des Weltkriegs, ",
        twentiethLink: "könnt ihr hier auf der Seite der New York Times anschauen",
        twentiethSecond: " (Inhalts-Hinweise: Vergewaltigung, sexuell-übertragbare Krankheiten, Tod).",
        sponsor: "Gefördert durch",
        altFirst: "Stromkasten mit vielen Graffiti Tags.",
        altSecond: "Die erleuchtete Frankfurter Skyline bei Nacht, die sich im Main spiegeln. ",
        altThird: "Eine Person überquert eine Straße bei Nacht. ",
        altFourth: "New York Times Website, auf der der Kurzfilm All her dying Lovers abspielbar ist.",
    },
    english: {
        date: "February 14, 2021",
        arrow: "BLOG << ",
        headline: " Change the narrative – A short report from the workshop „Documenting Nocturnal Flâneuserie“ by Anna Benner and Zoë Aiano",
        first: "In our two-day workshop „Documenting Nocturnal Flâneuserie“ with Anna Benner and Zoë Aiano on 16-17 January and 18-19 January, we tried to do exactly what the title suggests: document. Each of the female and non-binary participants went out into the night to capture their nocturnal strolling through film, voice recordings and photos. In turn, the camera changed how we perceived ourselves and the nocturnal city. We were driven by the question of what walking the city at night means for each of us individually as well as collectively as female-identified and non-binary people. Can there be such a thing as the flâneuse? In this blog entry we reflect on the discussions and provide readers with the additional material to discover their city through visual means.",
        second: "For the workshop, we met online on both days due to the pandemic. The night in between thus became our experimental space. After getting to know each other and exchanging ideas and experiences, the artists Anna Benner and Zoë Aiano sent everyone out with some tasks to flex, stroll, walk around in such different places as Frankfurt, Vienna, Berlin… The following day we met to talk about our experiences.",
        secondHeadlineFirst: "„I need to capture something exciting!“",
        third: "Moving through the city at night, is closely associated with the romanticised figure of the flâneur. A white man who walks the streets alone, perhaps feeling lonely, who observes the colourful hustle and bustle, the nocturnal (sex)workers, the life, but who himself  is not gazed at – simply because he can, because he appears to blend in. He is certain that the city belongs to him and his whole posture expresses this sentiment.The city just like its people becomes an object that he can appropriate. The image we have of the flâneur was in fact written by himself; he, the independent flâneur, the bohemian writer – he alone decides what is of value for a story to be told by him and has in turn shaped decisively the image of cities like Paris or Berlin and their (nocturnal) inhabitants.",
        fourthFirst: "Read more about the whiteness of the flaneur and what it means to be travelling European metropoles as a Black working class man in Johnny Pitt’s marvellous contemporary travel diary interwoven with a lot of knowledge on (Black) Europea, diaspora, literature, racism, cold-war politics, Black feminism and much more: “Afropean” published by Penguin (",
        bookLink: "https://www.penguin.co.uk/books/300/300186/afropean/9780141987286.html",
        fourthLink: "click here to learn more about the book",
        fourthSecond: ").",
        fifth: "What does this mean for us who are on a flâneuserie quest in this workshop? What does it mean for us as women and non-binary people to walk through the night as bodies not read as cis male? Some participants tell us that the workshop will be the first time since the Corona pandemic has started that they will be walking around the city at night – with the flâneur’s promise of something different or exciting in their luggage.",
        sixth: "Back the next evening, one participant reports her disappointment and anger that this promise did not deliver for her. Wanting to find what corresponds to the romanticised image of the ’special‘ place and the things worth documenting clashed with her feeling that in her eyes everything “looks like shit“. She says: „I had two feelings towards everything I was filming: it doesn’t fit the idea and I like that, but maybe I also do have a desire for that kind of idea representing reality- of life around me and me walking by myself through that life and that is really not there.“",
        big: "Listening tip: ",
        podcastText: "Photographer Rut Blees Luxemburg talks about capturing the forgotton and not obvious spaces ",
        podcastPhotoLink: "(click here to see a collection of Rut Blees Luxemburgs night shots)",
        seventh: "We talk about how, for us, walking at night is often limited to reaching a particular destination and even more so to our place-bound social life. With bars, pubs and clubs closed, the night now often remains deserted. But still, says one participant, for her, walking around alone at night marks a place of desire. Looking into lit windows and longing for cosiness, which she imagines is there, or – before Corona times – seeing people coming out of the club and longing for the self-indulgent lifestyle she believes these people to represent.  „That gives me a certain kind of sadness.“ Maybe sometimes we even find melancholic pleasure in that sadness, too?",
        eighth: "Others recall how they’ve enjoyed the loneliness of the corona-conditioned empty streets that were snow-covered during those nights. Those places that are usually busy during the day felt as if the places were there just for them. It meant less catcalling, too. Less inhibitions, perhaps. A different experience in contrast to other nights filled with catcalls, uninvited approaches, ass-grabbing, keys and pepper sprays held firmly, violence. Instead: A lone dancer in a white dress dancing by the river with headphones – a performance just for the flâneuse who dances along.",
        secondHeadlineSecond: "„Why do I feel less afraid when I’m not walking alone?“",
        ninth: "Anna and Zoë’s instructions included walking alone in the park. Most participants said they did not do it alone. Only when they had someone with them did they feel safe enough to enter these barely lit spaces. Why is that? And do we as flâneuses really need to be alone? What about mothers with a child? What if I have a dog with me? What if I’m on the phone? Does that actually keep us from “properly” experiencing the city? Or is that kind of solemn loneliness just the flâneur’s phantasm that isolates him from what it means to actually live in a place? The solitary figure of the flâneuer is impossible to think of in terms of relationships. He moves “freely” through the city and without responsibility for anything or anyone. Perhaps the flâneuse, in contrast, is someone who is involved in relationships, who has responsibility and gives care – which perhaps harbors safety for her,  too, knowing that she is not alone. The flâneuse experiences a city that is not an abstract idea, but a lived reality coming with all different kinds of herstories, biographies, limitations, relations, joys.",
        tenthFirst: "Still, fear is a constant and ambivalent companion for many participants. One of them confides in us how she paniced after a solitary man had approached her. Only seconds later does she realise that he is a bottle collector and asks herself in horror: „What kind of fears am I projecting?” Many of us who are white women are told from an early age that it is not safe outside of the house, especially at night and that we need to be afraid of „strange“ men („Stranger Danger“). This narrative of the stranger is – as George Yency (",
        tenthLinkFirst: "click here for reading the text of George Yency to „Walking while black in the white gaze“",
        tenthSecond: ") and Sara Ahmed (",
        tenthLinkSecond: "click here for reading the text of Sara Ahmed about „Making Strangers“",
        tenthThird: "), among others, point out – strongly interwoven with racist and classist narratives.",
        eleventh: "You can click here to read more about the racist and classist figure of „the stranger“.",
        secondHeadlineThird: "„I really hope that the flâneuse or radical flaneuserie could be a way to not only empower ourselves to go out but also to change the narrative of where we are endangered or why we are endangered.“",
        twelfth: "workshop participant",
        thirteenth: "It could help shift the focus intersectionally. Which bodies are actually endangered in the dark and why? When we think about who or what a flâneuse is or what she can do, we cannot do so without thinking about all forms of social power relations.",
        secondHeadlineFourth: "„not having a destination but a purpose“ – capturing nocturnal flaneuserie by filming",
        fourteenth: "The camera in our hand changes the perception of our strolling. Even without the camera recording, it can direct our gaze and make it search for the exciting and extraordinary. We talk about how we felt about filming. The camera gives one participant a reason to be walking aimlessly, which she normally never is – especially at night. Another participant reports that with and through the camera she was less afraid of being alone on the road: „I had a distance between me and the scene and at the same time I was in it. I felt the camera as an empowering object. I actively took ownership of the night.“",
        fifteenth: "",
        sixteenth: "We also talk about the fun that filming can be, about the absurd situations that we had found ourselves in Greeting a filming person on the other side since they surely are part of our workshop, only to awkwardly realise that they are in fact not and simply filming around.  Discovering absurd shop windows in one’s own neighbourhood that one has already walked past a hundred times without noticing. A long night walk can be ‚invigorating‘, can make us ‚feel our bodies‘ or just a feeling of freedom. ",
        secondHeadlineFifth: "Documenting Nocturnal Flaneuserie at the festival",
        seventeenth: "From the recorded film footage, photos and memos as well as from our online conversations, filmmakers Anna Benner and Zoë Aiano are making an animated short documentary. It will be screened for the first time at the festival Nocturnal Unrest in May 2021 at Künstler:innenhaus Mousonturm and online. ",
        thirdHeadlineFirst: "Artists:",
        eighteenthFirst: "Anna Benner is an artist and filmmaker working in animation, drawing and installation. She studied Illustration and Film Aesthetics in London and Oxford. Since 2014 she lives and works in Berlin, where she opened her Gallery ",
        eighteenthLinkFirst: "graumalerei",
        eighteenthSecond: " which focuses on contemporary illustration and serves as a space for artists to push their medium and explore in a safe and friendly space. Her main medium of expression is digital hand-drawn rotoscope animation. In her work she likes to explore human psychology and emotions, sometimes in their broken state but also with a portion of humor. Her artwork has been exhibited in Berlin, London and Paris and her animated short films have screened at festivals worldwide.",
        eighteenthLinkSecond: "To learn more about Anna you can click this link",
        eighteenthThird: "or follow her on Instagram @annabennerstudio.",
        nineteenthFirst: "Eluned Zoë Aiano is a filmmaker, video editor and translator focussed on Central and Eastern Europe. Her area of interest is visual ethnography on the border between documentary and art, social practice and experimentation. She is currently working on Flotacija, her first feature documentary, together with the other members of Wild Pear Arts. Wild Pear/Divlja Kruška is a team of 4 women with the shared goal of visualizing underrepresented perspectives artistically and engagingly, with a focus on the Balkan region (",
        nineteenthLinkFirst: "when you click here, you can learn more about Wild Pear",
        nineteenthSecond: "). She is also a regular contributor to the East European Film Bulletin (",
        nineteenthLinkSecond: "when you click here, you can learn more about the bulletin",
        nineteenthThird: ").",
        nineteenthLinkThird: "To learn more about Zoe you can click here.",
        thirdHeadlineSecond: "You want to get some impression of Annas and Zoës work?",
        twentiethFirst: "Check out their the animated short film “All her Dying Lovers” about an urban legend telling the story of a Nazi killilng nurse from WWII Czechoslovakia. ",
        twentiethLink: "Follow the link to New York Times",
        twentiethSecond: " (CW: rape, STI, death).",
        sponsor: "Sponsored by",
        altFirst: "Electricity box with many graffiti tags.",
        altSecond: "The illuminated Frankfurt skyline at night, reflected in the Main. ",
        altThird: "A person crosses a road at night. ",
        altFourth: "New York Times website showing the short film All her dying lovers.",
    }
}
export {changeTheNarrativeContent}